import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

// eslint-disable-next-line react/display-name
const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
          </defs>

          <g
            transform="translate(0.000000,413.000000) scale(0.100000,-0.100000)"
            fill={PRIMARY_MAIN}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          >
            <path
              d="M1423 4004 c-109 -17 -302 -65 -356 -87 -48 -20 -57 -46 -57 -158 l0
-89 -124 0 c-144 0 -148 -2 -156 -88 l-5 -55 -74 -41 c-97 -53 -224 -177 -280
-273 -108 -183 -147 -460 -94 -668 22 -89 57 -169 103 -236 22 -32 40 -61 40
-64 0 -3 -22 -33 -48 -68 -122 -157 -166 -269 -166 -427 0 -86 4 -115 23 -163
31 -78 88 -147 159 -193 l60 -39 -89 -83 c-147 -139 -213 -258 -240 -431 -17
-112 4 -238 56 -341 86 -169 318 -296 635 -347 147 -23 546 -24 680 0 195 34
370 99 497 185 78 53 191 175 230 248 117 220 115 485 -3 672 l-34 52 163 0
c187 0 208 7 255 85 l27 45 638 0 c421 0 645 4 658 10 19 10 19 33 19 1104 0
891 -2 1096 -13 1104 -8 7 -67 13 -138 14 l-124 3 -5 107 c-3 59 -9 111 -15
117 -17 15 -193 67 -315 92 -103 21 -141 24 -355 24 -222 -1 -249 -3 -365 -27
-69 -15 -157 -38 -197 -52 l-72 -24 -76 24 c-180 57 -306 76 -535 80 -157 2
-240 -1 -307 -12z m607 -108 c183 -37 257 -62 264 -89 3 -12 6 -71 6 -132 l0
-110 -387 3 c-300 2 -406 6 -468 18 -44 9 -140 18 -212 21 l-133 6 0 108 0
108 70 21 c102 30 237 59 332 69 137 16 395 5 528 -23z m1124 24 c176 -17 408
-75 420 -106 3 -9 6 -466 6 -1016 l0 -1000 -22 5 c-13 3 -68 17 -122 31 -289
76 -627 74 -934 -5 -62 -16 -115 -29 -118 -29 -2 0 -4 456 -4 1013 l0 1013 68
22 c223 70 462 95 706 72z m704 -1367 l2 -1013 -642 0 c-354 0 -649 -4 -657
-9 -8 -5 -21 -31 -30 -57 -23 -70 -39 -75 -204 -72 -138 3 -139 3 -163 31 -13
15 -24 39 -24 51 0 46 -20 51 -234 57 -111 4 -232 11 -271 17 -38 6 -200 16
-360 22 -159 6 -327 13 -373 17 l-82 6 0 193 0 193 38 -13 c21 -8 64 -19 95
-26 l57 -11 0 -113 c0 -101 2 -115 20 -131 20 -18 23 -18 103 8 199 63 348 87
562 87 212 0 352 -22 561 -87 l82 -25 83 27 c220 69 374 90 622 82 198 -6 308
-24 479 -77 156 -48 138 -164 138 903 0 502 3 922 6 935 6 22 10 23 98 20 l91
-3 3 -1012z m-2553 715 c133 -28 248 -119 305 -240 37 -79 50 -146 50 -257 0
-253 -136 -445 -351 -496 -35 -8 -97 -15 -136 -15 l-73 0 0 509 0 510 28 4
c35 5 113 -1 177 -15z m-295 -498 c0 -565 12 -516 -112 -452 l-73 37 -3 407
-2 407 33 25 c39 30 115 65 140 66 16 0 17 -30 17 -490z m1287 138 c-4 -178
-7 -500 -7 -717 l0 -393 -22 6 c-208 58 -325 77 -515 83 -207 7 -416 -19 -587
-72 -27 -8 -53 -15 -58 -15 -4 0 -8 29 -8 63 l0 64 128 6 c278 14 483 102 643
275 123 133 186 297 196 507 9 194 -29 338 -120 457 l-37 48 183 3 c100 1 188
3 196 5 12 2 13 -46 8 -320z m-1567 -150 c-1 -245 -2 -271 -16 -253 -45 60
-67 260 -41 381 15 71 41 144 51 144 4 0 6 -123 6 -272z m-18 -715 c16 -15 18
-35 18 -215 l0 -198 -37 19 c-101 51 -135 150 -95 268 16 44 78 142 91 143 3
0 13 -7 23 -17z m1348 -613 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
4 10 6 0 11 -4 11 -10z m-810 -190 c270 -10 357 -23 456 -70 107 -51 174 -152
174 -259 0 -56 -28 -161 -55 -206 -68 -118 -240 -209 -444 -236 -129 -17 -414
-6 -519 20 -153 38 -257 105 -305 196 -17 32 -21 57 -21 131 0 86 2 96 39 170
30 61 59 100 130 171 l92 93 104 0 c57 -1 214 -5 349 -10z"
            />
          </g>
          {/* <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              fill="url(#BG1)"
              d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
            />
            <path
              fill="url(#BG2)"
              d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
            />
            <path
              fill="url(#BG3)"
              d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
            />
          </g> */}
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
